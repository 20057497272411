import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

import PageHeader from '@common/src/pageheader/pageheader'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import SearchBox from '@common/src/common/inputbox/searchBox'

import TableHeader from './components/TableHeader'
import Loading from './components/Loading'
import { useSelector } from 'react-redux'
import AlertModal from '@common/src/common/alertModal/alertModal'
import Pagination from '@pulse/components/common/Pagination/pagination'
import { calcOffset } from '@common/src/common/helper'
import FilterDropdown from '@common/src/common/inputbox/filterBox'
import { ALL_OPTION, AVAILABLE_OPTIONS, INITIAL_PAGINATION_PROPS, STATUS_OPTIONS } from '@pulse/constants/constants'

const GenericAvailabilityList = ({
  query,
  queryName,
  columns,
  statusAction,
  pageTitle,
  searchPlaceholder,
  children,
  activeOnly = true
}) => {
  const toast = useToast()
  const [searchQuery, setSearchQuery] = useState('')
  const [rows, setRows] = useState([])
  const { selectedStore } = useSelector((state) => state.auth)
  const [selectedItem, setSelectedItem] = useState(null)
  const [showMenuUpdateConfirModal, setShowMenuUpdateConfirModal] = useState(false)
  const [availability, setAvailability] = useState(ALL_OPTION.common.value)
  const [pagination, setPagination] = useState(INITIAL_PAGINATION_PROPS)

  const { data, refetch, loading } = useQuery(query, {
    variables: {
      limit: pagination.limit,
      offset: calcOffset(searchQuery, pagination.currentPage, pagination.limit, pagination.totalRecords),
      search: searchQuery || '',
      filter: availability !== ALL_OPTION.common.value ? { isAvailable: availability } : {},
      companyId: parseInt(selectedStore?.companyId),
      storeId: parseInt(selectedStore?.value),
      ...(activeOnly ? { status: STATUS_OPTIONS[0].value } : {})
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (data?.[queryName]) {
      setRows(data[queryName].rows)
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: data[queryName].totalPages,
      }))
    } else {
      setRows([])
    }
  }, [data])

  useEffect(() => {
    refetch({
      limit: pagination.limit,
      offset: calcOffset(searchQuery, pagination.currentPage, pagination.limit, pagination.totalRecords),
      search: searchQuery || '',
      companyId: parseInt(selectedStore?.companyId),
      storeId: parseInt(selectedStore?.value),
      filter: availability !== ALL_OPTION.common.value ? { isAvailable: availability } : {},
      ...(activeOnly ? { status: STATUS_OPTIONS[0].value } : {})
    })
    setPagination(INITIAL_PAGINATION_PROPS)
  }, [selectedStore, searchQuery, availability])

  const onConfirm = () => {
    handleStatusChange()
    setShowMenuUpdateConfirModal(false)
  }

  const onClose = () => {
    setShowMenuUpdateConfirModal(false)
  }

  const onStatusToggleAlert = (item, record) => {
    setSelectedItem({ item, record }) // Set the selected record for the modal
    setShowMenuUpdateConfirModal(true) // Open the modal
  }
  const handleStatusChange = async () => {
    try {
      const formData = { storeId: Number(selectedStore?.value), ...selectedItem.item }

      await statusAction(formData)

      setRows((prevRows) => prevRows.map((row) => (row.id === selectedItem.record.id ? { ...row, isAvailable: selectedItem.item.status } : row)))

      toast.success(
        selectedItem.item.status
          ? `${pageTitle} marked as available in the store!`
          : `${pageTitle} marked as not available in the store.`
      )
    } catch (error) {
      toast.error(`Error updating ${pageTitle.toLowerCase()} availability.`)
    }
  }

  return (
    <>
      <PageHeader currentpage={pageTitle} activepage={`Availability`} mainpage={pageTitle} />
      <div className="grid grid-cols-12 gap-6">
        <div className="xl:col-span-12 col-span-12">
          <div className="box">
            <div className="box-header flex items-center justify-between">
              <div className="box-title">{pageTitle} Items</div>
              <div className="flex items-center">
                <FilterDropdown
                  className="mr-2 w-[200px]"
                  options={[ALL_OPTION.common, ...AVAILABLE_OPTIONS]}
                  value={AVAILABLE_OPTIONS.find((opt) => opt.value === availability) || ALL_OPTION.common}
                  onChange={(e) => { setAvailability(e.value) }}
                />
                <SearchBox
                  value={searchQuery}
                  placeHolder={searchPlaceholder}
                  classes='!py-2 !px-2 !mr-2'
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="box-body">
              <div className="table-responsive mb-4">
                <table className="table whitespace-nowrap table-bordered min-w-full">
                  <TableHeader columns={columns} />
                  <tbody>
                    {loading ? (
                      <Loading />
                    ) : (
                      <>
                        {rows?.length > 0 ? (
                          rows.map((item) => children({ item, pagination, rows, onStatusToggleAlert }))
                        ) : (
                          <tr>
                            <td colSpan={columns.length}>No items found</td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} setPagination={setPagination} rows={rows} />
            </div>
          </div>
        </div>

      </div>
      <AlertModal
        isOpen={showMenuUpdateConfirModal}
        title="Confirm"
        message={`Are you sure you want to change the status to ${selectedItem?.item?.status ? 'active' : 'inactive'}?`}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </>
  )
}

GenericAvailabilityList.propTypes = {
  query: PropTypes.object.isRequired,
  queryName: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  statusAction: PropTypes.func.isRequired,
  pageTitle: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  activeOnly: PropTypes.bool,
}

export default GenericAvailabilityList
